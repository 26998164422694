import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`When GM's 2023 Chevrolet Corvette Z06 Information Roll Out Plan?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "668px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e646b29fde65970ec897bb08d13689f8/fdf4b/chevy-corvette-z06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFXVRWKhNK//8QAGxAAAgEFAAAAAAAAAAAAAAAAAQIDABEhIjH/2gAIAQEAAQUCLWcyECOXDUDs3f/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAERAAIgIjH/2gAIAQEABj8CBcYsXNu4f//EABoQAQEBAAMBAAAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8hcaIaoKfmHjbXbgJzkIFlyffhv//aAAwDAQACAAMAAAAQnw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgIDAQAAAAAAAAAAAAABACERMUFhcVH/2gAIAQEAAT8QxoLWfWNnJNsB4e4FqDYtN5lQD6QqVgoamQt6dE//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2023 chevrolet corvette z06",
            "title": "2023 chevrolet corvette z06",
            "src": "/static/e646b29fde65970ec897bb08d13689f8/fdf4b/chevy-corvette-z06.jpg",
            "srcSet": ["/static/e646b29fde65970ec897bb08d13689f8/f93b5/chevy-corvette-z06.jpg 300w", "/static/e646b29fde65970ec897bb08d13689f8/b4294/chevy-corvette-z06.jpg 600w", "/static/e646b29fde65970ec897bb08d13689f8/fdf4b/chevy-corvette-z06.jpg 668w"],
            "sizes": "(max-width: 668px) 100vw, 668px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The new supercharged Corvette will hit showrooms by the middle of July, but if you want to know when you can expect to receive it, you have a few questions to ask. First, what will it look like? There's a lot of speculation out there, but it should be interesting to see how the company handles this situation.`}</p>
    <p>{`The Corvette Z06 is expected to debut in 2023 and will be built in California. GM's goal is to become an all-electric automaker by 2035 and end production of internal combustion engines. Those who have waited that long will be delighted to find out that the next generation of the Chevrolet Corvette will be electric. That would be a significant shift from the current V8.`}</p>
    <p>{`GM also has plans to make a feature film announcing the new model and discussing it with car enthusiasts. This will be available to the public on October 26, with a panel discussion to follow. In the meantime, the company has released the first official photo of the 2023 Corvette Z06 on its website. It's unclear when this will happen, but if the new model is launched in the spring, there's a good chance it will hit dealerships by then.`}</p>
    <p>{`While GM has not revealed a firm release date for the 2023 Corvette Z06, the brand's Twitter page has reported that a feature film will be available on the same day as the information roll out. The film will be followed by a panel discussion, and the first official photo of the 2023 Corvette will be released to the public on the same day. It will be produced at the Bowling Green Assembly plant in Kentucky.`}</p>
    <p>{`While the end of the 2022 model year is a big question, the company has released a 31-page document detailing its plan for the car. This document details how the system will work and includes five event-based triggers. The CANS Reference Guide will also outline the new information roll out strategy for the 2022 Corvette. This information roll-out is expected to go live by December 2021.`}</p>
    <p>{`GM's roll-out plan for the 2023 Corvette Z06 information roll-out date is still unclear. It has said that it has no plans to release the electric Corvette, but President Biden has twice said he has heard nothing about it. Nevertheless, he believes that the current Z06 is a world-class performance car. Its engine can compete with the Porsche and Ferrari.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      